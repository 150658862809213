nav {
  width: 100%;
}

.menu-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1450px;
  margin: 0 auto;
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
}

.topbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  max-width: 1450px;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.logo {
  font-size: 20px;
  position: absolute;
  font-weight: 700;
  left: 2rem;
  letter-spacing: 2px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  list-style: none;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: white;
}

.dark-mode .menu {
  background: #0f182a;
  z-index: 99;
}

.mobile-right {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.mobile-right div {
  margin-right: 10px;
}

.menu li a {
  text-decoration: none;
  padding: 5px 10px;
  font-size: 15px;
  letter-spacing: 2px;
  color: #0f182a;
}

.dark-mode .menu li a {
  color: #dbe1e9;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.button-none {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit; /* İkon rengini metin rengine eşitlemek için */

  padding: 0;
}

.language-dropdown {
  position: relative;
  display: inline-block;
}

.language-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 999;
  margin: 0 5px;
  background: white;
}

.dark-mode .language-dropdown-content {
  background: #0f182a;
}

.language-dropdown:hover .language-dropdown-content {
  display: block;
}
.mobile-logo {
  /* text-align: left; */
  left: 0;
  padding-left: 19px;
  top: 1rem;
  position: absolute;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-logo img {
  margin-right: 10px;
}

.logo img {
  margin-right: 10px;
}

/* Dil seçenekleri buton stilleri */
.language-dropdown button {
  background: none;
  display: flex;
  border: none;
  cursor: pointer;
  color: inherit;
  padding: 5px;
  margin: 0 5px;
  font-size: 1rem;
}

.language-dropdown a {
  background: none;
  display: flex;
  border: none;
  cursor: pointer;
  color: inherit;
  padding: 5px;
  margin: 0 5px;
  font-size: 1rem;
}

.language-dropdown button:hover {
  color: deepskyblue;
}

@media screen and (max-width: 968px) {
  .menu-container {
    justify-content: space-between;
  }
  .topbar {
    position: relative;
    right: 0;
    z-index: 99999;
    padding-left: 0;
    padding-right: 0;
  }
  .menu {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    flex-direction: column;
    border-radius: 5px;
    z-index: 99;
  }

  .mobil-menu-open {
    display: flex;
    height: 100vh;
  }

  .logo {
    display: none;
  }

  .menu li {
    margin: 10px 0;
  }

  .hamburger-menu {
    display: flex;
  }
}

@media screen and (min-width: 968px) {
  .mobile-logo {
    display: none;
  }
}
