.container {
  width: 100%;
  position: relative;
}

.tabs {
  max-width: 1450px;
  height: auto;
  margin: 0 auto;
  display: flex;
}

.tabs button {
  background-color: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  padding-right: 60px;
  white-space: nowrap;
  transition: all 0.5s ease-out;
  padding-bottom: 2rem;
}
.dark-mode .tabs button {
  color: #dbe1e9;
}

.tabs svg {
  margin-bottom: 1rem;
  font-size: 30px;
  width: 100%;
}

.tabs button:hover {
  color: skyblue;
}

.tabs button:disabled {
  background: transparent;
  color: #3b51dd;
  font-weight: 600;
}

.title {
  color: color;
  font-size: medium;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.content {
  padding: 50px 0;
  padding-left: 2rem;
  background-color: transparent;
  font-weight: 300;
  line-height: 30px;
  font-size: 16px;
  border-top: 1px solid #f1f5f90d;
  border-bottom: 1px solid #f1f5f90d;
  max-width: 1450px;
  height: auto;
  margin: 0 auto;
  display: flex;
  padding-right: 2rem;
}

.content,
button {
  font-size: 15px;
}

@media only screen and (max-width: 960px) {
  .container {
    width: 100%;
    margin: 0 auto;
  }
  .tabs button {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 10px;
    max-width: 100%;
    margin-left: 10px;
  }
  .tabs {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .content {
    padding: 50px;
  }
}

:root {
  --background-dark: #2d3548;
  --text-light: rgba(255, 255, 255, 0.6);
  --text-lighter: rgba(255, 255, 255, 0.9);
  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 64px;
  --width-container: 1200px;
}

.hero-section {
  align-items: flex-start;
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
  padding-top: 20px !important;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 100%;
}

@media (max-width: 768px) {
  .card__heading {
    font-size: 24px !important;
  }
  .content,
button {
  font-size: 10px;
}

}

@media (max-width: 568px) {
  .card__heading {
    font-size: 16px !important;
  }
}

@media (min-width: 340px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .card-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1300px) {
  .card-grid {
    grid-template-columns: repeat(6, 1fr);
    max-width: 100%;
  }
}
@media (min-width: 1250px) {
  .hero-section {
    display: flex;
    min-height: 100%;
    width: 1450px;
    max-width: 100%;
    margin: 0 auto;
    justify-content: flex-start !important;
    padding: 0;
  }
}

.card {
  list-style: none;
  position: relative;
}

.card:before {
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.card__background {
  background-size: cover;
  background-position: center;
  border-radius: var(--spacing-l);
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  transform: scale(1) translateZ(0);
  transition: filter 200ms linear, transform 200ms linear;
}

.card:hover .card__background {
  transform: scale(1.05) translateZ(0);
}

.card-grid:hover > .card:not(:hover) .card__background {
  filter: brightness(0.3) saturate(0.4) contrast(1) blur(10px);
}

.card__content {
  left: 0;
  padding: var(--spacing-l);
  position: absolute;
  top: 0;
}

.card__category {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.card__heading {
  color: var(--text-lighter);
  font-size: 1.9rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  word-spacing: 100vw;
}
