#hizmet-detay { 
    width: 100%;
}

#hizmet-detay .container {
    align-items: center;
    max-width: 1450px;
    margin: 0 auto;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
    margin-bottom: 4rem;

}

#hizmet-detay img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.hizmet-detay-baslik {
    font-size: 35px;
    text-transform: uppercase;
    margin-top: 1.3rem;
    margin-bottom: 2rem;
    font-weight: 600;
    letter-spacing: 0.5px;
}

#hizmet-detay p {
    line-height: 28px;
    margin-bottom: 1.3rem;
}

