* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

body h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.dark-mode {
  background-color: #0f182a;
  color: #dbe1e9;
}

.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {
  color: #dbe1e9;
}

.light-mode {
  background-color: #dbe1e9;
  color: #0f182a;
}

.light-mode h1,
.light-mode h2,
.light-mode h3,
.light-mode h4,
.light-mode h5,
.light-mode h6 {
  color: #0f182a;
}

.pages-title {
  width: 1450px;
  margin: 0 auto;
  padding-left:  2rem;
  padding-right: 2rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}


@media screen and (max-width: 1200px) { 
  .pages-title {
    width: 100%;
  }

} 