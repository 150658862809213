.footer {
  width: 100%;
}

.footer-section {
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 1450px;
  max-width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2.5rem;
  margin-top: 2rem;
}

.footer-section div {
}

.footer-section h2 {
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #0f182a;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.dark-mode .footer-section h2 {
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #fff;
}

.footer-section li {
  list-style: none;
  color: #64748b;
  font-size: 13.5px;
  line-height: 28px;
  letter-spacing: 0px;
  font-weight: 500;
}

.dark-mode .footer-section li {
  list-style: none;
  color: #7e8da0;
}

@media only screen and (max-width: 960px) {
  .footer-section {
    display: block;
  }
}
