.card-container-fluid {
  width: 100%;
  margin-top: 3rem;
}
.card-container {
  width: 1450px;
  max-width: 100%;
  margin: 0 auto;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}

.cards h2 {
  font-size: 20px;
  display: flex;
  font-weight: 400;
  letter-spacing: 0.5px;
  align-items: center;
}

.cards h2 span svg {
  font-size: 24px;
  align-items: center;
  display: flex;
  margin-right: 1rem;
}

.card-content {
  box-shadow: 0 3.2px 2.2px rgba(0, 0, 0, 0.02),
    0 7px 5.4px rgba(0, 0, 0, 0.028), 0 12.1px 10.1px rgba(0, 0, 0, 0.035),
    0 19.8px 18.1px rgba(0, 0, 0, 0.042), 0 34.7px 33.8px rgba(0, 0, 0, 0.05),
    0 81px 81px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid whitesmoke;
}

.dark-mode .card-content {
  border: 1px solid #222f43;
}

@media (max-width: 1200px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 568px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

.name {
  font-size: 20px;
  font-weight: bold;
  margin: 16px 0;
}

.description {
  font-size: 14px;
  color: #777;
  line-height: 1.8;
  padding-bottom: 20px;
}
