#project-detail {
  width: 100%;
}

#project-detail .container {
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
}


#project-detail .col-6 {
  width: 50%;
}

#project-detail .col-12 {
  width: 100%;
}

#project-detail .col-8 {
  width: 60%;
}

#project-detail .col-4 {
  width: 40%;
}


#project-detail .m-100 {
  margin-top: 7rem !important;
}

#project-detail .pr-2 {
  padding-right: 2rem;
}
#project-detail .project-information {
  gap: 1rem;
  display: grid;
  margin-top: 1rem;
}

#project-detail .project-information p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 2rem;
  letter-spacing: 1px;
  line-height: 20px;
}

#project-detail .project-text-features {
  font-weight: 300;
  letter-spacing: 0.5px;
  display: flex;
  font-size: 15px;
}

#project-detail .project-title {
  font-size: 30px;
  margin-top: 1rem;
  font-weight: bold;
}

#project-detail .project-date {
  font-size: 13px;
  letter-spacing: 2px;
}

#project-detail .project-information span {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  width: 100px;
  line-height: 20px;
}

#project-detail .project-information a {
  width: 50%;
  display: flex;
  background-color: transparent;
  border: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
}



#project-detail a {
  align-items: center;
}

#project-detail button svg {
  margin-left: 5px;
  font-size: 25px;
}

#project-detail .project-view {
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 16px;
}

.dark-mode #project-detail .project-view {
  color: cornflowerblue;
  text-decoration: none;

}

#project-detail .project-information a:hover {
  color: 3b51dd;
  transition: 1.5s;
}

#project-detail img {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 1.3rem;
}

.comeback {
  border: 1px solid black;
  width: 30px;
  height: 30px;
  font-size: 15px;
  margin-bottom: 1rem;
  display: flex;
  border-radius: 30px;
  align-items: center;
}

.dark-mode .comeback {
  border: 1px solid white;
}

.comeback svg {
  margin: 0 auto;
}

@media screen and (max-width: 968px) {
  #project-detail .container {
    display: block;
  }
  #project-detail .col-6 {
    width: 100%;
  }
  #project-detail .project-title {
  }

  #project-detail .col-8 {
    width: 100%;
  }
  
  #project-detail .col-4 {
    width: 100%;
  }
}

@media screen and (min-width: 968px) {

  #project-detail .stabil{
    position: sticky;
    top: 0;
    height: fit-content;
  }
 
}
