#orientation {
  width: 100%;
  position: relative;
  padding: 2rem;
}

.dark-mode #orientation {
  width: 100%;
  position: relative;
  padding: 2rem;

  border-bottom: 1px solid #222f43;
}

#orientation .container {
  margin: 0 auto;
  display: flex;
  width: 1450px;
  max-width: 100%;
  justify-content: space-between;
  border-radius: 8px;
  font-weight: 500;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
#orientation svg {
  margin-right: 3px;
  font-size: 14px;
}
#orientation .title-icon svg {
  font-size: 25px;
}

#orientation h1 {
  height: 38px;
  margin: 60px 0;
  letter-spacing: 0.5pxpx;

  font-weight: 600;
}
#orientation h1 span {
  white-space: nowrap;
}

.flex-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 350px;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 80vw;
  max-width: 1300px;
  position: relative;
  z-index: 0;
}

input {
  width: 25px;
  height: 25px;
  background-color: #3b51dd;
  position: relative;
  border-radius: 50%;
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
 input:focus {
  outline: none;
}
 input::before,
 input::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #3b51dd;
  width: 11.6vw;
  height: 2px;
  max-width: 150px;
}

 input::before {
  left: calc(-11.8vw + 12.5px);
}

 input::after {
  right: calc(-11.8vw + 12.5px);
}
 input:checked {
  background-color: #3b51dd;
}
 input:checked::before {
  background-color: #3b51dd;
}
 input:checked::after {
  background-color: #aeb6bf;
}
input:checked ~ input,
 input:checked ~ input::before,
 input:checked ~ input::after {
  background-color: #aeb6bf;
}
#orientation input:checked + .dot-info span {
  font-size: 14px;
  font-weight: bold;
}

.dot-info {
  width: 25px;
  height: 25px;
  display: block;
  visibility: hidden;
  position: relative;
  z-index: -1;
  left: calc((((80vw - 25px) / 20) * -1) - 1px);
}
.dot-info span {
  visibility: visible;
  position: absolute;
  font-size: 13px;
}
.dot-info span.year {
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}
.dot-info span.label {
  top: -65px;
  left: 0;
  transform: rotateZ(-45deg);
  width: 70px;
  text-indent: -10px;
}

#timeline-descriptions-wrapper {
  width: 100%;
  margin-top: 70px;
  font-size: 16px;
  font-weight: 400;
  margin-left: calc((-80vw - 25px) / 20);
}

#timeline-descriptions-wrapper p {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.5px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  display: none;
}

input[data-description='1']:checked
  ~ #timeline-descriptions-wrapper
  p[data-description='1'] {
  display: block;
}

input[data-description='2']:checked
  ~ #timeline-descriptions-wrapper
  p[data-description='2'] {
  display: block;
}

input[data-description='3']:checked
  ~ #timeline-descriptions-wrapper
  p[data-description='3'] {
  display: block;
}

input[data-description='4']:checked
  ~ #timeline-descriptions-wrapper
  p[data-description='4'] {
  display: block;
}

input[data-description='5']:checked
  ~ #timeline-descriptions-wrapper
  p[data-description='5'] {
  display: block;
}

input[data-description='6']:checked
  ~ #timeline-descriptions-wrapper
  p[data-description='6'] {
  display: block;
}

input[data-description='7']:checked
  ~ #timeline-descriptions-wrapper
  p[data-description='7'] {
  display: block;
}

@media (min-width: 1250px) {
  .input-flex-container {
    margin-left: 125px;
  }

  input::before {
    left: -125px;
  }
  input::after {
    right: -125px;
  }
  .dot-info {
    left: calc((((1000px - 25px) / 20) * -1) - 1px);
  }

  #timeline-descriptions-wrapper {
    margin-left: -37.5px;
  }
}
@media (max-width: 630px) {
  .flex-parent {
    justify-content: initial;
  }

  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 400px;
    height: auto;
    margin-top: 2.5rem;
    margin-left: 0;
    padding-bottom: 30px;
  }

  #orientation input,
  .dot-info {
    width: 60px;
    height: 60px;
    margin: 0 10px 50px;
  }

  #orientation input {
    background-color: transparent !important;
    z-index: 1;
  }
  input::before,
  input::after {
    content: none;
  }
  input:checked + .dot-info {
    background-color: #2c3e50;
  }
  input:checked + .dot-info span.year {
    font-size: 14px;
  }
  input:checked + .dot-info span.label {
    font-size: 12px;
  }

  .dot-info {
    visibility: visible;
    border-radius: 50%;
    z-index: 0;
    left: 0;
    margin-left: -70px;
    background-color: #aeb6bf;
  }
  .dot-info span.year {
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ecf0f1;
  }
  .dot-info span.label {
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    text-indent: 0;
    text-align: center;
  }

  #timeline-descriptions-wrapper {
    margin-top: 30px;
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .input-flex-container {
    width: 340px;
  }
}
@media (max-width: 400px) {
  .input-flex-container {
    width: 300px;
  }
}
