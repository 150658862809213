*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Some basic CSS overrides */
#profilCard {
  line-height: 1.5;
  font-family: 'Be Vietnam Pro', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

button,
input,
select,
textarea {
  font: inherit;
}

a {
  color: inherit;
}

/* End basic CSS override */
.profile {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  width: 100%;
  max-width: 370px;
  background-color: #1b2028;
  border-radius: 16px;
  position: relative;
  border: 3px solid transparent;
  background-clip: padding-box;
  text-align: center;
  color: #f1f3f3;
  background-image: linear-gradient(
    135deg,
    rgba(117, 46, 124, 0.35),
    rgba(115, 74, 88, 0.1) 15%,
    #1b2028 20%,
    #1b2028 100%
  );
}
.profile:after {
  content: '';
  display: block;
  top: -3px;
  left: -3px;
  bottom: -3px;
  right: -3px;
  z-index: -1;
  position: absolute;
  border-radius: 16px;
  background-image: linear-gradient(
    135deg,
    #752e7c,
    #734a58 20%,
    #1b2028 30%,
    #2c333e 100%
  );
}

.profile-image {
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 175px;
  position: relative;
}
.profile-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.profile-username {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1.5rem;
}

.profile-user-handle {
  color: #7d8396;
}

.profile-actions {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-actions > * {
  margin: 0 0.25rem;
}

.profile-links small {
  display: flex;
  margin-bottom: 0.3rem;
  align-items: center;
  font-size: 14px;

}

.profile-links  a {
  text-decoration: none;
}

.profile-links small svg {
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  font-size: 20px;
}

.btn {
  border: 0;
  background-color: transparent;
  padding: 0;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 1;
  transition: 0.15s ease;
}
.btn--primary {
  border-radius: 99em;
  background-color: #3772ff;
  background-image: linear-gradient(135deg, #5587ff, #3772ff);
  color: #fff;
  padding: 0 1.375em;
}
.btn--primary:hover,
.btn--primary:focus {
  background-size: 150%;
}
.btn--icon {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 3px solid #343945;
  color: #7d8396;
}
.btn--icon svg {
  font-size: 1.25em;
}
.btn--icon:hover,
.btn--icon:focus {
  border-color: #7d8396;
}

.profile-links {
  margin-top: 3.5rem;
}

.link {
  text-decoration: none;
  color: #7d8396;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}
.link i {
  font-size: 1.25em;
}
