#contactForm {
  width: 100%;
  position: relative;
}

.containerForm {
  max-width: 1450px;
  height: auto;
  margin: 0 auto;
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
}

form {
  width: 100%;
  display: flex;
}

#contactForm .col-6 {
  width: 50%;
  box-sizing: border-box;
}
#contactForm .col-6 h1 {
  margin-top: 20px;
  margin-bottom: 2rem;
}
#contactForm .col-6 h5 {
  margin-bottom: 1.5rem;
  color: grey;
}

#contactForm .form-group {
  margin-bottom: 1rem;
}



#contactForm h5 {
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 300;
  font-size: 20px;
}

.containerForm input,
select,
textarea {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: transparent !important;
  border: 1px solid #3b51dd;
  color: grey;
  padding-left: 1rem;

  margin-top: 0.4rem;
  margin-bottom: 0.7rem;
}




.containerForm input:hover,
select:hover,
textarea:hover {
  border: 1px solid #3b51dd;
}

.containerForm ::placeholder {
  color: grey;
}

.containerForm textarea {
  margin: 0 auto;
  height: 145px;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.containerForm .error {
  margin-bottom: 20px;
  font-size: 14px;
  color: #ff3131;
}

.containerForm button {
  background: #3b51dd;
  padding: 10px 40px;
  border: 1px solid #3b51dd;
  color: white;
  border-radius: 5px;
  margin-top: 1rem;
}
#contactForm input:focus,
#contactForm select:focus,
#contactForm textarea:focus {
  outline: none;
}

#contactForm input:-webkit-autofill,
#contactForm select:-webkit-autofill,
#contactForm textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #0f182a inset !important;
  -webkit-text-fill-color: white !important;
}
.formImg img {
  max-width: 580px;
  size: cover;
  object-fit: contain;
}
label {
  color: grey;
}

.success { margin-top: 2rem;}

.formImg { margin-top: 4rem;}

@media screen and (max-width: 1200px) {


   .containerForm { display: block !important;}
  #contactForm .col-6 {
    width: 100%;
    order: 1;
 
  }

  #contactForm .col-6:first-child {
    order: 2; /* İlk .col-6 öğesi ikinci sırada olacak */
    margin-top: 2rem;
  }

  #contactForm .col-6:last-child {
    order: 1; /* İkinci .col-6 öğesi ilk sırada olacak */
  }

  .container {
    display: block;
  }

  form { flex-direction: column; }

}



