.services-container-fluid {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 5rem;
  margin-top: 4rem;
}

.services-container {
  max-width: 1450px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid whitesmoke;
  border-radius: 8px;
  padding: 3rem;
  box-sizing: border-box;
}
.dark-mode .services-container {
  max-width: 1450px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #131c31;
  border-radius: 8px;
  border-color: #222f43 !important;
  border: 1px solid;
  padding: 3rem;
  box-sizing: border-box;
}




.slide-style {
  height: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin-top: 50px;
}

.slide-style img {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  object-fit: cover;
}

.slide-style span {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: url('https://genz-nextjs-v2.vercel.app/_next/static/media/bg-trans.f1fa91bd.png')
    repeat-x 0 100%;
}
.slide-style span h2 {
  position: absolute;
  padding-left: 1rem;
  font-weight: 500;
  font-size: 15px;
  bottom: 2rem;
}
.slide-style h6 {
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 200;
  font-weight: 400;
  position: absolute;
  color: skyblue;
  bottom: 0.5rem;
  padding-left: 1rem;
}
.slide-style h6:hover {
  color: skyblue;
  transition-duration: 0.5s;
}
.swiper-button-next {
  color: skyblue;
  top: 20px;
  left: 3rem;
}
.swiper-button-prev {
  color: skyblue;
  top: 20px;
}
.swiper-button-next:after {
  font-size: 20px;
}
.swiper-button-prev:after {
  font-size: 20px;
}
.text-area h1 {
  font-weight: 600;
}

.text-area p {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  font-size: 13px;
}
