#services { 
    width: 100%;
}

#services .container {
    align-items: center;
    max-width: 1450px;
    margin: 0 auto;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
}
#services .services {
    display: flex; 
  justify-content: space-between; 
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  }

  #services .services-title {
    margin-top: 1rem;
  }
  
.dark-mode .services-card {
    border: 1px solid #222f43;
}
  .services-card {
    display: flex;
    border: 1px solid lightgray;
    flex-direction: column;
    padding: 16px;
    max-width: 100%; /* isteğe göre ayarlayın */
    margin: 16px auto; /* merkezde tutmak için */
    box-shadow: 0 3.2px 2.2px rgba(0, 0, 0, 0.02), 0 7px 5.4px rgba(0, 0, 0, 0.028), 0 12.1px 10.1px rgba(0, 0, 0, 0.035), 0 19.8px 18.1px rgba(0, 0, 0, 0.042), 0 34.7px 33.8px rgba(0, 0, 0, 0.05), 0 81px 81px rgba(0, 0, 0, 0.07);
    border-radius: 30px;
    padding: 1rem;
    margin: 1rem;
}



.services-card a {
    list-style: none;
    text-decoration:none;
}

.services-card-body {
    display: flex;
    align-items: center; /* içerikleri dikey olarak merkezde tut */
    margin: 0 auto;
}

.services-card-title {
    display: flex;
    align-items: center;
    flex-basis: 20%; /* sol tarafın genişliği, ihtiyacınıza göre ayarlayın */
}

.dark-mode .services-card-title h3 {
    color: #dbe1e9;
}
.services-card-title h3 {
    color: #0f182a;
}



.services-card-title svg {
  font-size: 40px;
  margin-right: 10px;
}

.services-card-description {
    flex-basis: 60%; /* orta kısım genişliği */
    padding: 0 16px; /* isteğe göre ayarlayın */
}

.dark-mode .services-card-button {
    border:1px solid #7e8da0;
}


.services-card-button {
    
    right:0;
    border: 1px solid lightgray;
    border-radius: 150px;
    width: 150px;
height: 150px;
display: flex;
text-decoration: none;
justify-content:center;
color: #7e8da0;
align-items: center;
margin-left: 20px;

}

.services-card-button:hover {
    color: #3b51dd;
    border-color: #3b51dd;
}


/* Mobil için */
@media (max-width: 768px) {
    .services-card-body {
        flex-direction: column;
    }

    .services-card-title,
    .services-card-description,
    .services-card-button {
        flex-basis: 100%;
        margin-bottom: 16px; /* isteğe göre ayarlayın */
    }
}






.services-card-button svg {
    margin-left: 5px;
    font-size: 20px;
}


@media only screen and (max-width: 960px) { 

    .services-card-button {
        position: relative;
        padding: 0;
      right: 0;
       
    }

    .services-card {
        display: flex; 
        flex-direction: row; 
        width: 100%;
        max-height: 100%;
        margin-bottom: 20px;
        border-bottom: 1px solid lightgray;
      }
}
