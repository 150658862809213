.about .container-fluid {
  width: 100%;
}

.about h1 {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  font-weight: 500;
}

.about .container {
  max-width: 100%;
  width: 1450px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.about img {
  border-radius: 10px;
  max-width: 100%;
}

.about .col-left {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.about .col-right {
  width: 50%;
}

.about .text-hakkimizda {
  padding: 1rem;
}

.about .col-right h2 {
  margin-top: 2rem;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 24px;
}
.about .col-right p {
  margin-top: 3rem;
  letter-spacing: 0.8px;
  font-weight: 400;
  line-height: 25px;
  font-size: 15px;
}

.about .col-6 {
  display: flex;
  width: 50%;
  margin: 2rem;
  border-radius: 10px;
}

.about .green-gradient {
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #5810d5,
    #4a00e0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #5810d5,
    #4a00e0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.about .box-about {
  padding: 2rem;
  display: grid;
  gap: 1rem;
}

.box-about h3 {
  letter-spacing: 3px;
  color: white;
}
.box-about p {
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  line-height: 22px;
  color: white;
}

.about button {
  background-color: transparent;
  color: white;
  border: none;
  text-align: center;
  justify-content: start;

  display: grid;
  letter-spacing: 2px;
  font-weight: 700;
  margin-top: 1rem;
}

@media (max-width: 968px) {
  .about .container {
    display: block;
  }
  .about .col-left {
    width: 100%;
  }

  .about .col-right {
    width: 100%;
  }

  .about .col-6 {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}
