#references {
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.ref-container {
  width: 1450px;
  max-width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;
}
.ref-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 25%;
}
.ref-right {
  flex: 0 0 75%;
}
#references .contain {
  display: flex;
  justify-content: space-between;
}
#references a {
  display: flex;
}
.ref-text {
  display: flex;
}
.references {
  padding: 2.2rem;
  max-width: 100%;
  display: flex;
  box-sizing: border-box;
}

.ref-say span {
  font-size: 47px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.references:hover {
  background-image: linear-gradient(
    165deg,
    hsl(178, 100%, 44%),
    hsl(209, 100%, 44%)
  );
}

@media only screen and (max-width: 1200px) {
  .ref-container {
    display: block;
    max-width: 100%;
  }

  #references .contain {
    justify-content: space-between;
    display: flex;
  }
}

@media only screen and (max-width: 968px) {
  .references {
    padding: 1rem;
  }
}

@media only screen and (max-width: 720px) {
  .references {
    padding: 1rem;
  }

  #references .contain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .mobile-ref-group {
    display: flex;
    width: 100%;
  }

  .mobile-ref {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 1rem;
  }

  .references {
    width: 100%;
    max-width: none;
  }
}