#not-found .container {
    width: 100%;
    max-width: 1450px;
    margin: 0 auto;
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
  }

  #not-found .container img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  