

.sectionOne {
  max-width: 1450px;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  display: flex;
}

.home-left {
  width: 50%;
}
.home-left h1 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  font-size: 30px;
}
.home-left p {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.home-right {
  width: 50%;
  height: auto;
  padding-top: 3rem;
}
.home-right img {
  object-fit: cover;
  max-width: 100%;
  width: 580px;
  position: relative;
}

@media screen and (min-width: 1300px) {
  .container-fluid {
    width: 1450px;
    margin: 0 auto;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
  }
 }

@media screen and (max-width: 968px) {


  .sectionOne {
    display: block;
    padding-top: 2rem;
  }
  .container-fluid {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .home-left {
    width: 100%;
    padding-top: 3rem;
    text-align: center;
  }
  .home-right {
    padding-top: 1rem;
    align-items: center;
    margin: 0 auto;
  }
  .home-left h1 {
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 800;
  }
  .home-left p {
    letter-spacing: 0.5px;
    font-size: 14px;
  }
}

/* ÖZEL BUTTON */
.button-gradient {
  position: relative;
  padding: 0;
  border: none;
  z-index: 5;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: box-shadow 150ms;
}

.bg-btn-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: grid;
  grid-template-columns: 1fr;
  z-index: -1;
}

.btn-bg {
  grid-row-start: 1;
  grid-column-start: 1;
}

.bg-1 {
  animation: bg-1-animation 8s infinite;
  background-image: linear-gradient(
    165deg,
    hsl(178, 100%, 44%),
    hsl(209, 100%, 44%)
  );
  border-radius: 5px;
}
.bg-1:before {
  content: '';
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(
    165deg,
    hsl(178, 100%, 44%),
    hsl(209, 100%, 44%)
  );
  filter: blur(35px);
}
.bg-2 {
  animation: bg-2-animation 8s infinite;
  background-image: linear-gradient(
    165deg,
    hsl(0, 84%, 61%),
    hsl(47, 95%, 57%)
  );
  border-radius: 5px;
}
.bg-2:before {
  content: '';
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(
    165deg,
    hsl(0, 84%, 61%),
    hsl(47, 95%, 57%)
  );
  filter: blur(35px);
}
.bg-3 {
  animation: bg-3-animation 8s infinite;
  background-image: linear-gradient(
    165deg,
    hsl(330, 100%, 50%),
    hsl(270, 67%, 46%)
  );
  border-radius: 5px;
}
.bg-3:before {
  content: '';
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(
    165deg,
    hsl(330, 100%, 50%),
    hsl(270, 67%, 46%)
  );
  filter: blur(35px);
}

.button-text {
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dbe1e9;
  background-color: #0f182a;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 1px;
  border-radius: 5px;
  padding: 0.75rem 3rem;
  font-size: 1rem;
  transition: all 100ms;
}

.button-text svg {
  margin-left: 10px;
  font-size: 20px;
}

.button:hover .button-text {
  transition: all 100ms;
  background-color: white;
  color: black;
}

@keyframes bg-1-animation {
  0%,
  16.667%,
  to {
    opacity: 1;
  }

  33%,
  83.333% {
    opacity: 0;
  }
}

@keyframes bg-2-animation {
  0%,
  16.667%,
  66.667%,
  to {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }
}

@keyframes bg-3-animation {
  0%,
  50%,
  to {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }
}

.mt-2 {
  margin-top: 2rem;
  
}